<template>
    <QDynamicSubmenu
        :default-title="title"
        :default-buttons="buttons"
    />
</template>

<script>
//table data + search
import QDynamicSubmenu from '@/components/navigation/Submenus/base/QDynamicSubmenu.vue'

export default {
    name: "QAdInsertionOrderSubmenu",
    data() {
        return {
            title: "Ad Insertion Orders",
        }
    },
    computed: {
        buttons() {
            const adInsertionOrders = {
                label: "Ad Insertion Orders",
                to: "/agency-building/ad-insertion-orders",
                icon: "fas fa-arrow-alt-circle-right",
                id: "v-bc"
            }

            if (this.hasRole(['SuperAdmin', 'Staff', 'Exec'])) {
                return [
                    adInsertionOrders,
                    {
                        label: "Manage Markets",
                        to: "/agency-building/ad-insertion-markets",
                        icon: "fas fa-arrow-alt-circle-right",
                        id: "v-bc"
                    },
                ]
            }

            if (this.hasRole(['AgencyOwner'])) {
                return [adInsertionOrders]
            }

            return [];
        },
    },
    components: {
        QDynamicSubmenu,
    },
}
</script>

<style></style>
