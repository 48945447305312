<template>
    <div>
        <v-card>
            <QDataTableHeader>
                <template #left>
                    <p class="q-display-3 mt-0 mb-0 white--text text-no-wrap">
                        {{ title }}
                        <v-btn dark icon @click="refreshData">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                    </p>
                </template>
                <template #right>
                    <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details></v-text-field>
                </template>
            </QDataTableHeader>

            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table dense v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="id" class="elevation-0 row-pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" v-on:click:row="loadAgent">
                <!--adds view ticket icon -->
                <template v-slot:item.ContractStartDt="{ item }">
                    <span class="nowrap">
                        {{ dateDaysSince(item.ContractStartDt) }}
                    </span>
                </template>
                <template v-slot:item.ID="{ item }">
                    <span>
                        6 <v-icon v-if="item.AppsSubmittedFirst6Weeks >= 6">fas fa-check</v-icon>
                    </span>
                </template>
                <template v-slot:item.AgentName="{ item }">
                    <span>
                        {{ item.AgentName }}
                        <!--
                        <span v-if="item.Status == 'Active'">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on" right>fas fa-running</v-icon>
                                </template>
                                <span>Agent Status is Active in Opt</span>
                            </v-tooltip>
                        </span>
                        //-->
                    </span>
                </template>
            </v-data-table>
			<v-row v-if="!loading">
				<v-col cols="12" class="text-center">
					<v-btn color="primary" @click="exportResults">
						<v-icon left>fas fa-file-export</v-icon> Export Results
					</v-btn>
				</v-col>
			</v-row>
        </v-card>
    </div>
</template>
<script>
import QGenericDataTableMixin from "./QGenericDataTableMixin"
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from './base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'
const { Parser } = require('json2csv')

export default {
    name: "QNewAgentAppsDataTable",
    props: ['agent'],
    mixins: [QGenericDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            //use this to "preset" a filter
            filters: {},
            options: {
                'sortBy': [],
                'sortDesc': [true]
            },
            expanded: [],
        }
    },
    computed: {
        loading: function() {
            return this.data_loading;
        },
        all_data: function() {
            return this.the_data;
        },
        filter_keys: function() {
            return [];
        },
        total_items: function() {
            return this.total_num;
        },
        the_headers: function() {
            return [{
                    text: 'Agent',
                    value: 'AgentName',
                    class: "nowrap",
                    sortable: true,
                }, {
                    text: 'Days Since Start',
                    value: 'ContractStartDt',
                    sortable: true,
                    align: "center"
                },
                /* {
                    text: 'Apps Total',
                    value: 'AppsSubmittedTotal',
                    sortable: true,
                    align: "center"
                },*/
                {
                    text: 'Apps 1st 6 Weeks',
                    value: 'AppsSubmittedFirst6Weeks',
                    sortable: true,
                    align: "center"
                },
                {
                    text: 'Goal',
                    value: 'ID',
                    sortable: false,
                    align: "center"
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            const g = this
            g.data_loading = true
            QuilityAPI.getNewAgents(g.agent.AgentCode, { ...opt }, g.role).then(function(json) {
                g.data_loading = false
                g.$set(g, 'the_data', json.data)
                g.total_num = json.total
            }).catch(function(err) {
                g.data_loading = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
            g.firstPull = false;
        }, 200),
        loadAgent: function(agent) {
            this.$router.push({ name: 'AgentDetail', params: { agentcode: agent.AgentCode } })
        },
		exportResults: function() {
			const g = this
            //exporting exactly what is in the API returned data.
            const fields = g.exportFields //this.export_fields;
            const opts = { fields }
            try {
                let parser = new Parser(opts)
                let csv = parser.parse(g.the_data)
                let fileURL = window.URL.createObjectURL(new Blob([csv]))
                let fileLink = document.createElement('a')
                fileLink.href = fileURL
                let fileName = g.slugify(g.title + " " + g.endDate)
                fileLink.setAttribute('download', fileName + ".csv")
                document.body.appendChild(fileLink)
                fileLink.click()
                g.logActivity("Agent Recruits Export", { "leaderboard": g.title })
            } catch (err) {
                console.error(err);
            }

        },
    },
    watch: {

    },
    components: {
        QDataTableHeader,
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

.row-pointer tbody tr:hover {
    cursor: pointer;
}

</style>
