<template>
    <div>
        <v-card>
            <QDataTableHeader class="q_leads_1" id="ad-insertion_datatable">
                <template #left>
                    <p class="q-display-3 pb-0 pt-0 mt-0 mb-0 white--text">{{ title }}
                        <v-btn v-if="moreLink" text fab :to="{name:moreLink}">
                            <v-icon color="q_leads_4">fas fa-arrow-circle-right</v-icon>
                        </v-btn>
                        <v-btn dark icon @click="doReloadData">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                        <v-btn class="ml-10" @click="newOrder">Add New Order</v-btn>
                    </p>
                    <p class="pt-0 mt-0">
                        <v-btn text small color="q_leads_4" @click="toggleFilters">
                            <v-icon small left>fas fa-filter</v-icon> Filter
                        </v-btn>
                    </p>
                </template>
                <template #right>
                    <v-text-field dense dark v-model="search" :disabled="isDemoMode" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="doReloadData"></v-text-field>
                </template>
            </QDataTableHeader>
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <QExpandableDataTable v-on:current-items="updateCurrentItems" :visibleHeaders="visibleHeaders" :mobile-breakpoint="2" :search="search" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" color="q_leads_1">
                <template v-slot:item.AgentName="{ item }">
                    <v-badge :content="item.Division" :overlap="false" inline :offset-x="-5" color="primary" overlap>{{item.AgentName}}</v-badge>
                </template>
                <template v-slot:item.LiveAdDate="{ item }">
                    {{formatDate(item.LiveAdDate)}}
                </template>
                <template v-slot:item.CreateDate="{ item }">
                    {{formatDate(item.CreateDate)}}
                </template>
                <template v-slot:item.ID="{ item }">
                    <span class="nowrap" v-if="hasRole(['Exec', 'SuperAdmin', 'Staff'])">
                        <v-btn icon color="q_new_b_1 white--text  body-2" @click="editOrder(item)">
                            <v-icon>fas fa-edit</v-icon>
                        </v-btn>
                        <v-btn icon color="red white--text  body-2" @click="deleteOrder(item)">
                            <v-icon>fas fa-trash</v-icon>
                        </v-btn>
                    </span>
                    <span class="nowrap" v-else-if="item.Status != 'Canceled'">
                        <v-btn color="q_new_b_1 white--text  body-2" @click="cancelOrder(item)">
                            Cancel
                        </v-btn>
                    </span>
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan=2>
                            <v-btn v-if="hasRole(['Exec', 'SuperAdmin', 'Staff'])" @click="downloadData">Export</v-btn>
                        </td>
                        <td colspan=8>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                </template>
            </QExpandableDataTable>
        </v-card>
        <v-dialog v-model="show_edit_order" width="600">
            <v-card class="pa-6 text-center" min-height="">
                <v-form lazy-validation v-model="valid" ref="form">
                    <v-progress-linear color="primary" indeterminate v-if="saving"></v-progress-linear>
                    <h3 v-if="currentOrder.ID">Edit Order</h3>
                    <h3 v-else>New Order</h3>
                    <!-- <v-text-field v-if="hasRole('AgencyOwner')" label="Agency Code" v-model="this.user.Agent.AgentCode"></v-text-field> -->
                    <q-agent-lookup :rules="[rules.required]" v-if="hasRole(['Exec', 'SuperAdmin', 'Staff'])" :filters="{'Status' : 'Active,Available,Stalled', 'LeadershipLevel' : 'Agency Owners and Above'}" label="Agent" v-model="currentOrder.AgentCode" placeholder="Start typing to Search for Agent"></q-agent-lookup>
                    <v-select :rules="[rules.required]" :items="available_markets" label="Market" item-text="MarketName" item-value="ID" v-model="currentOrder.AdInsertionMarketID"></v-select>
                    <v-select :rules="[rules.required]" v-if="hasRole(['Exec', 'SuperAdmin', 'Staff'])" :items="available_statuses" label="Status" v-model="currentOrder.Status"></v-select>
                    <q-date-picker v-if="hasRole(['Exec', 'SuperAdmin', 'Staff']) || currentOrder.LiveAdDate" :disabled="!hasRole(['Exec', 'SuperAdmin', 'Staff'])" v-model="currentOrder.LiveAdDate" label="Live Ad Date"></q-date-picker>
                    <div v-if="!this.hasRole(['Exec', 'SuperAdmin', 'Staff'])" style="max-height:100px;border:1px solid #ccc; overflow:auto;" class="mb-2">
                        <q-page slug="quility-ad-compliance-policy"></q-page>
                    </div>
                    <v-checkbox v-if="!this.hasRole(['Exec', 'SuperAdmin', 'Staff'])"
                        v-model="checkbox"
                        :rules="[rules.required]"
                        label="I have read and understood Quility's recruitment policies."
                    ></v-checkbox>
                    <v-btn v-if="hasRole(['Exec', 'SuperAdmin', 'Staff']) || currentOrder.ID == null" @click="saveOrder">Submit Order</v-btn>
                </v-form>
            </v-card>
        </v-dialog>
        <v-dialog v-model="show_confirm_cancel" width="500">
            <v-card class="pa-6 text-center" min-height="">
                Are you sure you want to cancel this order?
            </v-card>
            <v-divider></v-divider>
            <v-card-actions style="background:#fff;">
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="show_confirm_cancel = false"
                >
                    No, Don't Cancel
                </v-btn>
                <v-btn
                    color="red"
                    text
                    @click="cancelOrder(currentOrder)"
                >
                    Cancel the Order
                </v-btn>
            </v-card-actions>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "./QDataTableMixin"
import QDatePicker from '@/components/utils/QDatePicker.vue'
import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import Agent from '@/store/Models/Agent'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import QDataTableHeader from './base/QDataTableHeader.vue'
import { debounce } from 'vue-debounce'
import QExpandableDataTable from '../datatables/QExpandableDataTable.vue'
import rules from '@/components/utils/ValidationRules'
import QPage from '@/CMS/views/index.vue';

export default {
    name: "QAgentsDirectoryTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            expanded: [],
            entity: 'ad-insertion',
            //use this to "preset" a filter
            filters: { Status: '-All-',  Market: '-All-'},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            // options: { itemsPerPage: 25, sortBy: ['CreateDate'], sortDesc: [true] },
            options: {
                'sortBy': ['CreateDate'], //used to set the default sorting column
                'sortDesc': [true],
                'itemsPerPage': 10
            },
            
            the_data: [],
            loading_data: false,
            show_edit_order: false,
            currentOrder: {},
            saving: false,
            available_markets: [],
            rules: rules, //validation rules imported.
            available_statuses: [
                "New",
                "Approved",
                "Ad Placed",
                "Canceled",
                // "Waitlist"
            ],
            valid: false,
            show_confirm_cancel: false,
            reloadData: true,
            checkbox: false
        }
    },
    mounted: function() {
        this.loading_data = true
        this.getMarkets()
    },
    computed: {
        filter_keys: function() {
            return ['Status','Market']
        },
        all_data: function() {
            return this.the_data.filter(row => {
                var { Status, Market } = this.filters
                if(Status != '-All-' && row.Status != Status)
                    return false
                if(Market != '-All-' && row.MarketName != Market)
                    return false
                return true
            })
        },
        expandTableHeaders() {
            return []
        },
        the_headers: function() {
            const headers = [{
                text: 'Order Date',
                value: 'CreateDate',
                align: 'start',
                sortable: true,
                filterable: true,
                class: 'nowrap',
            }, {
                text: 'Agent',
                value: 'AgentName',
                align: 'start',
                class: 'nowrap',
            }, {
                text: 'Market',
                value: 'MarketName',
                align: 'start',
                class: 'nowrap',
            }, {
                text: 'Status',
                value: 'Status',
                align: 'center',
                class: 'nowrap',
            }, {
                text: 'Live Ad Date',
                value: 'LiveAdDate',
                align: 'center',
                class: 'nowrap',
            }, {
                text: '',
                value: 'ID',
                align: 'center',
                class: 'nowrap',
            }];

            if (this.$vuetify.breakpoint.lgAndDown) {
                return [
                    ...this.expandTableHeaders,
                    ...headers,
                ]
            }

            return headers
        },
        visibleHeaders() {
            if (this.$vuetify.breakpoint.smAndDown) {
                return [{
                    text: 'Agent',
                    value: 'AgentName',
                    align: 'center',
                    class: 'nowrap',
                }, {
                    text: 'Market',
                    value: 'MarketName',
                    align: 'center',
                    class: 'nowrap',
                }]
            }

            if (this.$vuetify.breakpoint.mdAndDown) {
                return this.the_headers.slice(this.expandTableHeaders.length, this.expandTableHeaders.length + 4)
            }

            if (this.$vuetify.breakpoint.lgAndDown) {
                return this.the_headers.slice(this.expandTableHeaders.length, this.expandTableHeaders.length + 6)
            }

            return []
        },
        filterable_markets: function() {
            return new Set(this.the_data.map(row => row.MarketName).sort())
        },
        available_filters: function() {
            return {
                Status: ['-All-', ...this.available_statuses],
                Market: ['-All-', ...this.filterable_markets]
            };
        },
        loading: function() {
            return this.loading_data
        },
        total_items: function() {
            if(!this.the_data)
                return 0
            return this.the_data.total;
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            opt.itemsPerPage = 'all'
            opt.needAvailableFilters = true
            if (!this.reloadData)
                return
            
            this.reloadData = false

            var g = this
            this.loading_data = true
            QuilityAPI.getAdInsertionOrders(this.filters, { attr: '', ...opt }).then(function(json) {
                g.loading_data = false
                g.the_data = json.data
            }).catch(function(err) {
                g.loading_data = false
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
                //g.showError(err.status)
            });
            this.firstPull = false;
        }, 200),
        doReloadData: function() {
            this.reloadData = true
            this.refreshData()
        },
        getMarkets: debounce(function() {
            var g = this
            let opt = {
                itemsPerPage: 'all',
                sortBy: 'MarketName',
                sortDesc: false
            }
            QuilityAPI.getAdInsertionMarkets([], opt).then(function(json) {
                g.available_markets = json.data
                // g.available_markets = json.data.map(market => {
                //     if(market.HasSeatsAvailable)
                //         market.MarketName += " ("+market.CurrentSeatsAvailable+")"
                //     else
                //         market.MarketName += " (Waitlist)"
                //     return market
                // })
            }).catch(function(err) {
                if (err.status == 431) {
                    g.showError(err + " You probably need to clear all your cookie and session variables and login again.")
                    return
                }
            });
        }),
        downloadData: debounce(function() {
            var opt = {
                ...this.options,
                search: this.search,
            }
            var endpoint = '/api/private/ad_insertion_order';
            var params = {
                ...opt,
                ...this.filters,
                'itemsPerPage': "csv",
                'attr': ''
            }
            this.dispatchJob({
                'method': "GET",
                'path': endpoint,
                'params': params,
                'JobName': "Ad Insertion Order Export"
            })
        }, 200),

        newOrder: function() {
            this.currentOrder = {
                "AgentCode": null,
                "ID": null,
                "AdInsertionMarketID": null,
                "Status": "New",
                "LiveAdDate": null
            }
            this.getMarkets()
            this.show_edit_order = true
        },

        editOrder: function(i) {
            this.currentOrder = i
            this.show_edit_order = true
        },
        cancelOrder: function(i) {
            if(!this.show_confirm_cancel) {
                this.currentOrder = i
                this.show_confirm_cancel = true
                return
            }
            // i.Status = "Canceled"
            this.currentOrder.Status = "Canceled"
            this.show_confirm_cancel = false
            this.saveOrder()
        },
        deleteOrder: function(i) {
            var g = this
            g.loading_data = true;
            QuilityAPI.deleteAdInsertionOrder(i).then(function(json) {
                g.loading_data = false
                if (json.msg) {
                    g.showError(json.msg)
                    return
                }
                g.doReloadData()
            }).catch(function(err) {
                g.loading_data = false
                g.showError(err)
            });
        },

        saveOrder: function() {
            if (!this.currentOrder.Status == 'Canceled' && !this.$refs['form'].validate()) {
                this.goToTop();
                this.showError("Please correct invalid fields.")
                this.saving = false;
                return false;
            }

            if (!this.hasRole(['Exec', 'SuperAdmin', 'Staff']) && this.checkbox != true) {
                this.showError("Please accept the terms.")
                return false;
            }

            //if not staff, then must be an AO or above, so use their agent code
            if(!this.hasRole(['Exec', 'SuperAdmin', 'Staff']) && this.currentOrder.AgentCode == null)
                this.currentOrder.AgentCode = this.user.Agent.AgentCode

            var g = this
            g.saving = true;
            QuilityAPI.saveAdInsertionOrder(g.currentOrder).then(function(json) {
                g.saving = false
                if (json.msg) {
                    g.showError(json.msg)
                    return
                }
                g.show_edit_order = false
                g.doReloadData()
            }).catch(function(err) {
                g.saving = false
                g.showError(err)
            });
        },
        goToTop: function() {
            this.$vuetify.goTo('#topform', {
                duration: 500,
                easing: 'easeOutCubic',
                offset: 300
            })
        },
    },
    components: {
        QDataTableHeader,
        QExpandableDataTable,
        QAgentLookup,
        QDatePicker,
        QPage
    }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
