<template>
    <v-menu offset-x open-on-hover>
        <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
                <v-icon small>fas fa-question-circle</v-icon>
            </v-btn>
        </template>
        <v-card class="pa-4 q_white-text" color="rgba(0, 0, 0, 0.7);" style="max-width:300px; background-color:rgba(0, 0, 0, 0.8);">
            <slot></slot>
        </v-card>
    </v-menu>
</template>
<script>
export default {
    name: "QTooltipDetails",
    props: ['content'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        return {

        }
    },
    mounted: function() {

    },
    computed: {

    },
    methods: {

    },
    watch: {

    },
}

</script>
<style scoped>
</style>
