<template>
    <v-container fluid class="q-comparison-stat">
        <v-row>
            <v-col cols="12" class="pa-1" :style="'color:#fff;background-color:' + primaryColor ">
                <div>
                    <span class="q-chart-labels py-0 pl-4">
                        <span v-html="label"></span> {{ currentCategory }} :
                    </span>
                    <span class="q-chart-values text-right py-0">
                        <h4><span v-if="format == 'currency'">{{ formatCurrency(current) }}</span>
                            <span v-else>{{ current }}</span>
                        </h4>
                    </span>
                </div>
            </v-col>
            <v-col cols="12" class="py-0">
                <highcharts :options="ChartOptions"></highcharts>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { Chart } from 'highcharts-vue'
import colors from './ChartColors';
//import mockdata from '@/store/sample_data/MockDataPromotionTracker'
import theme from './Theme'
import dataTransformer from '@/store/dataTransformer'

export default {
    name: "QComparisonChart",
    props: ['label', 'currentCategory', 'previousCategory', 'previous', 'format', 'current', 'goal', 'primaryColor', 'secondaryColor', 'capPercentage', 'smallerIsGood'], // data passed to component from parent
    data: function() { // data internal to component (not available outside)
        var g = this;
        var max = this.previous > this.current ? this.previous * 1.25 : this.current * 1.25;
        if (max < this.goal) {
            max = this.smallerIsGood ? this.goal * .75 : this.goal * 1.25;
        }
        return {
            //uwGoal: uwGoal,
            ChartOptions: {
                chart: {
                    inverted: true,
                    marginLeft: 115,
                    type: 'bullet',
                    height: '85px'
                },
                title: {
                    text: null
                },
                legend: {
                    enabled: false
                },
                yAxis: {
                    gridLineWidth: 0
                },
                plotOptions: {
                    series: {
                        pointPadding: 0.25,
                        borderWidth: 0,
                        color: this.primaryColor,
                        targetOptions: {
                            width: '200%'
                        }
                    }
                },
                credits: {
                    enabled: false
                },
                exporting: {
                    enabled: false
                },
                xAxis: {
                    categories: [g.label], // + '<br>' + g.appended_text()]
                    labels: {
                        format: g.label + '<br>' + g.appended_text(),
                    },
                },
                yAxis: {
                    plotBands: [{
                        from: 0,
                        to: this.previous,
                        color: this.secondaryColor
                    }],
                    title: null,
                    labels: {
                        formatter: function(v) {
                            if (g.format == 'currency') {
                                return g.formatShortCurrency(this.value);
                            }
                            return this.value;
                        }
                    },
                    softMin: 0,
                    softMax: max,
                    tickAmount: 5
                },
                series: [{
                    data: [{
                        y: isNaN(g.current) ? 0 : g.current,
                        target: g.goal
                    }]
                }],
                tooltip: {
                    shared: true,
                    headerFormat: '<b>' + g.label + '</b><br>',
                    pointFormatter: function(v) {
                        var value = null
                        var prev_value = null
                        if (g.format == "currency") {
                            value = g.formatCurrency(g.current);
                            prev_value = g.formatCurrency(g.previous);
                        } else {
                            value = g.current;
                            prev_value = g.previous;
                        }
                        if (typeof g.previousCategory == 'undefined') {
                            return "<strong><span style='color:#000;'>●" + g.currentCategory + "</span> : <span style='color:#000;'><b>" + value + "</b></span></strong><br/>"
                        }
                        return "<strong><span style='color:#000;'>●" + g.previousCategory + "</span> : <span style='color:#000;'><b>" + prev_value + "</b></span></strong><br/>" +
                            "<strong><span style='color:#000;'>●" + g.currentCategory + "</span> : <span style='color:#000;'><b>" + value + "</b></span></strong><br/>"
                    },
                },
            }
        }
    },
    mounted: function() {
        //this.renderChart(this.chartData, this.options)
        //Chart.
    },
    computed: {

    },
    methods: {
        percentage_change: function() {
            if (this.smallerIsGood) {
                var c = (this.previous - this.current) / this.previous
            } else {
                var c = (this.current - this.previous) / this.previous
            }

            if (this.capPercentage && c > this.capPercentage) {
                return 1;
            }
            return c;
        },
        compare_value_class: function() {
            var r_class = 'compare-value';
            if (this.percentage_change() < 0) {
                return " " + r_class + " negative"
            }
            return " " + r_class + " positive"
        },
        appended_text: function() {
            var appended_text = "";
            if (this.previous) {
                if (this.previous == 0) {
                    return ""
                }
                return "<span class='compare value " + this.compare_value_class() + "'></span><span class='appended-text'>" + appended_text + "</span>";
            }
            return appended_text;
        },
        tooltip_appended_text: function() {
            return this.appended_text();
        },
    },
    watch: {

    },
    components: {
        highcharts: Chart
    }
}

</script>
<style scoped>
h4 {
    margin: 0;
    color: #fff;
}

.q-chart-labels {
    display: inline-block;
    float: left;
    width: auto;
}

.q-chart-values {
    display: inline-block;
    float: right;
    width: auto;
}

</style>
