<template>
    <v-container fluid class="q-comparison-stat">
        <v-row>
            <v-col cols="12" class="pa-1" :style="'color:#fff;background-color:' + primaryColor ">
                <div>
                    <span class="q-chart-labels py-0 pl-4">
                        <span v-html=label></span>:
                        <span v-html="category"></span>
                    </span>
                    <span class="q-char-values text-right py-0">
                        <h4>
                            {{ formatPercentage(percentage_change()) }}
                        </h4>
                    </span>
                </div>
            </v-col>
            <v-col cols="12" class="py-0">
                <highcharts :options="ChartOptions"></highcharts>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import QComparisonChart from './QComparisonChart.vue'

export default {
    extends: QComparisonChart,
    name: "QComparisonChartCloseRatio",
    props: ['category'],
    data: function() {
        var g = this
        return {

        }
    },
    methods: {
        percentage_change: function() {
            var c = this.current / this.previous
            if (this.previous == 0) {
                c = this.current / 1
            }
            if (c > 1) {
                return 1;
            }
            return c;
        },
        appended_text: function() {
            var appended_text = "";
            if (this.previous) {
                if (this.previous == 0) {
                    return ""
                }
                return "<span class='compare value " + this.compare_value_class() + "'>" + this.current + " " + this.currentCategory + " <br> " + this.previous + " " + this.previousCategory + "</span><span class='appended-text'>" + appended_text + "</span>";
            }
            return appended_text;
        },
        tooltip_appended_text: function() {
            var appended_text = "";
            if (this.previous) {
                if (this.previous == 0) {
                    return ""
                }
                return "<span class='compare value " + this.compare_value_class() + "'>(" + this.formatPercentage(this.percentage_change()) + ")</span><span class='appended-text'>" + appended_text + "</span>";
            }
            return appended_text;
        },
    }
}

</script>
<style scoped>
h4 {
    margin: 0;
    color: #fff;
}

</style>
<style>
.chart-subtitle {
    font-size: .5em;
    font-weight: 200;
    text-transform: uppercase;
}

.q-chart-labels {
    display: inline-block;
    float: left;
    width: auto;
}

.q-chart-values {
    display: inline-block;
    float: right;
    width: auto;
}

</style>
